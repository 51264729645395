var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "header-bottom"
  }, [_c('router-link', {
    staticClass: "left",
    attrs: {
      "to": "/incomeDetail?type=1"
    }
  }, [_c('div', {
    staticClass: "money"
  }, [_c('span', [_vm._v(_vm._s(_vm.parentData.money))]), _vm._v("元")]), _c('div', {
    staticClass: "tips"
  }, [_vm._v("金币每天凌晨自动兑换成现金")])]), _c('div', {
    staticClass: "right"
  }, [_c('router-link', {
    staticClass: "btn btn-to-wd",
    attrs: {
      "to": "/wd"
    }
  }, [_vm._v("提现")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };