/**
 * vant 的一些组件的使用
 * 用到什么组件就注册一下 
 */
/**
 * vant 的一些组件的使用
 */
import Vue from 'vue'
import { Lazyload,Skeleton, Tab, Tabs, Swipe,Dialog , SwipeItem, NoticeBar, Image as VanImage} from 'vant'
Vue.use(Lazyload)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(NoticeBar)
Vue.use(VanImage)
Vue.use(Skeleton)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Dialog)