var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "number-grow-warp"
  }, [_c('span', {
    ref: "numberGrow",
    staticClass: "number-grow",
    attrs: {
      "data-time": _vm.time,
      "data-value": _vm.value
    }
  }, [_vm._v("0")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };