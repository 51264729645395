import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'lib-flexible/flexible'
//注册全局的Vant组件
import './services/vant'
// import 'vant/lib/index.css';


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
