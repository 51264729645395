var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "chest-box"
  }, [_c('img', {
    staticClass: "chest-img",
    attrs: {
      "src": require("../assets/chest-box.png"),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "chest-red-coin",
    attrs: {
      "src": require("../assets/red-coin.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "chest-btn",
    on: {
      "click": function ($event) {
        return _vm.resetTime();
      }
    }
  }, [_c('img', {
    staticClass: "chest-btn-img",
    attrs: {
      "src": require("../assets/bg-chest-btn.png"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "chest-btn-text"
  }, [_vm._v(_vm._s(_vm.count))]), _vm.count === '开宝箱得金币' ? _c('img', {
    staticClass: "chest-light",
    attrs: {
      "src": require("../assets/light.png"),
      "alt": ""
    }
  }) : _vm._e()])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };