export default {
  name: "NumberGrow",
  props: {
    time: {
      type: Number,
      default: 0.5
    },
    value: {
      type: Number,
      default: 99999
    }
  },
  methods: {
    numberGrow(ele) {
      let _this = this;
      let step = Math.floor(_this.value * 10 / (_this.time * 1000));
      let current = 0;
      let start = 0;
      let t = setInterval(function () {
        start += step;
        if (start > _this.value) {
          clearInterval(t);
          start = _this.value;
          t = null;
        }
        if (current === start) {
          return;
        }
        current = start;
        ele.innerHTML = current.toString().replace(/(d)(?=(?:d{3}[+]?)+$)/g, "$1,");
      }, 10);
    }
  },
  mounted() {
    this.numberGrow(this.$refs.numberGrow);
  }
};