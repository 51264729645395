var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.isLoading ? _c('div', {
    staticClass: "loading"
  }, [_vm._v(" 数据加载中... "), _c('compSkeleton')], 1) : _c('div', {
    staticClass: "page"
  }, [_c('compHeaderTop', {
    class: {
      sticky: _vm.isSticky
    },
    attrs: {
      "parentData": _vm.htData,
      "isSticky": _vm.isSticky
    },
    on: {
      "childEvent": _vm.handleChildEvent,
      "updateActiveCoin": _vm.getActiveCoin
    }
  }), _c('compHeaderBottom', {
    class: {
      mt: _vm.isSticky
    },
    attrs: {
      "parentData": _vm.hbData
    }
  }), _c('router-link', {
    attrs: {
      "to": "/profit"
    }
  }, [_vm._v("去详情页")]), _c('compChestCoin')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };