import NumberGrow from '../components/NumberGrow';
export default {
  components: {
    NumberGrow
  },
  name: 'compHeaderTop',
  props: ['parentData', 'isSticky'],
  data() {
    return {
      activeCoin: localStorage.getItem('activeCoin') || 0,
      activeName: '今日活跃奖励',
      intervalId: null,
      isRunning: false
    };
  },
  created() {
    console.log(this.props);
    this.startCounter();
  },
  methods: {
    sendToParent() {
      this.$emit('childEvent', 'Data from child');
    },
    startCounter() {
      if (!this.isRunning) {
        this.isRunning = true;
        this.intervalId = setInterval(() => {
          if (this.activeCoin < 10) {
            // this.$emit('updateActiveCoin', this.activeCoin)
            this.activeCoin++;
            localStorage.setItem('activeCoin', this.activeCoin);
          } else {
            clearInterval(this.intervalId);
            this.isRunning = false;
          }
        }, 1000);
      }
    },
    getActiveReward() {
      setTimeout(() => {
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.isRunning = false;
        }
        this.activeCoin = 0;
        localStorage.setItem('activeCoin', this.activeCoin);
        this.startCounter();
      }, 100);
    }
  }
};