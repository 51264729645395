export default {
  data() {
    return {
      count: "",
      seconds: 5
    };
  },
  mounted() {
    this.Time();
  },
  methods: {
    resetTime() {
      if (this.count === "开宝箱得金币") {
        this.Time();
      }
    },
    countDown() {
      let h = parseInt(this.seconds / (60 * 60) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt(this.seconds / 60 % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      s = s < 10 ? "0" + s : s;
      this.count = h + ":" + m + ":" + s;
    },
    Time() {
      this.countDown(); //解决刷新页面时，间隔一秒才会显示的问题
      var timer = setInterval(() => {
        this.seconds -= 1;
        if (this.seconds <= 0) {
          this.seconds = 5;
          this.count = "开宝箱得金币";
          console.log("倒计时结束");
          clearInterval(timer);
        } else {
          this.countDown();
        }
      }, 1000);
    }
  }
};