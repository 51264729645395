var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "skeleton-screen"
  }, [_c('div', {
    staticClass: "skeleton-box"
  }), _c('div', {
    staticClass: "skeleton-box"
  }), _c('div', {
    staticClass: "skeleton-box"
  })]);
}];
export { render, staticRenderFns };