import compSkeleton from '@/components/Skeleton';
import compHeaderTop from '@/components/HeaderTop'; // 顶部吸顶
import compHeaderBottom from '@/components/HeaderBottom'; //顶部金额
import compChestCoin from '@/components/ChestCoin'; //宝箱金币
export default {
  components: {
    compSkeleton,
    compHeaderTop,
    compHeaderBottom,
    compChestCoin
  },
  data() {
    return {
      isLoading: false,
      pageState: {
        // 页面的数据和状态
      },
      isSticky: false,
      //是否吸顶
      stickyOffset: 0,
      htData: {
        coin: 99999,
        money: 3.37,
        videoCoin: 188,
        videoName: '看视频奖励',
        taskCoin: 188,
        taskName: '任务奖励'
      },
      // 给HeaderTop组件的数据
      hbData: {
        money: 3.37
      } // 给HeaderBottom组件的数据
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    dddd() {
      this.$toast('No need to import showToast');
    },
    // 获取当前激活的金币
    getActiveCoin(coin) {
      console.log(coin);

      //   // localStorage.setItem('activeCoin',coin);
    },
    // 是否滑动吸顶
    handleScroll() {
      console.log('是否滑动吸顶', window.pageYOffset);
      if (window.pageYOffset > 0) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    // 处理来自子组件的事件
    handleChildEvent(payload) {
      console.log('Event received from child with payload:', payload);
    }
  },
  mounted() {},
  beforeRouteLeave(to, from, next) {
    // 监听到页面离开时调用
    // to: 即将要进入的目标的路由对象
    // from: 当前导航正要离开的路由
    // next: 是一个function，一定要调用该方法，否则路由不会跳转。
    console.log('lid');
    // 一定要调用next
    next();
  },
  beforeDestroy() {},
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};