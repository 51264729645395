var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "header-top"
  }, [_c('router-link', {
    staticClass: "left",
    attrs: {
      "to": "/incomeDetail?type=0"
    }
  }, [_c('div', {
    staticClass: "today-tip"
  }, [_vm._v("今日金币收益")]), _c('NumberGrow', {
    attrs: {
      "value": _vm.parentData.coin
    }
  })], 1), _c('div', {
    staticClass: "right",
    class: {
      upDown: !_vm.isSticky
    }
  }, [_c('div', {
    staticClass: "bubble video"
  }, [_c('div', {
    staticClass: "bubble-bg"
  }, [_c('img', {
    staticClass: "icon-big-coin",
    attrs: {
      "src": require("../assets/icon-big-coin.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "bubble-num"
  }, [_vm._v(_vm._s(_vm.parentData.videoCoin))])]), _c('div', {
    staticClass: "bubble-text"
  }, [_vm._v(_vm._s(_vm.parentData.videoName))])]), _c('div', {
    staticClass: "bubble task"
  }, [_c('div', {
    staticClass: "bubble-bg"
  }, [_c('img', {
    staticClass: "icon-big-coin",
    attrs: {
      "src": require("../assets/icon-big-coin.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "bubble-num"
  }, [_vm._v(_vm._s(_vm.parentData.taskCoin))])]), _c('div', {
    staticClass: "bubble-text"
  }, [_vm._v(_vm._s(_vm.parentData.taskName))])]), _c('div', {
    staticClass: "bubble active",
    on: {
      "click": function ($event) {
        return _vm.getActiveReward();
      }
    }
  }, [_c('div', {
    staticClass: "bubble-bg"
  }, [_c('img', {
    staticClass: "icon-big-coin",
    attrs: {
      "src": require("../assets/icon-big-coin.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "bubble-num"
  }, [_vm._v(_vm._s(_vm.activeCoin))])]), _c('div', {
    staticClass: "bubble-text"
  }, [_vm._v(_vm._s(_vm.activeName))])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };