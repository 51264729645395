import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {//首页
    path: '/',
    name: 'home',
    component: HomeView
  },
  { //提现
    path: '/wd',
    name: 'wd',
    component: () => import(/* webpackChunkName: "profit" */ '../views/WdView.vue')
  },
  { //提现详情页
    path: '/wdDetail',
    name: 'wdDetail',
    component: () => import(/* webpackChunkName: "profit" */ '../views/WdDetailView.vue')
  },
  {//收入明细（金币、现金）
    path: '/incomeDetail',
    name: 'incomeDetail',
    component: () => import(/* webpackChunkName: "profit" */ '../views/IncomeDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
